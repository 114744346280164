.k404-bg {
	background-repeat: no-repeat;
	background-position: center right;

	.k404-header {
		margin-top: 50px;
	}

	@include at-most($screen-sm-max) {
		background-position: bottom right;
	}

	@include at-most($screen-xs-max) {
		background-position: top right;
	}
}
