* {
	-webkit-font-smoothing: antialiased;
}

html {
	font-size: 100%;
}

body {
	@extend %text;
	line-height: 1.4;
	color: $color-text-primary;
	font-family: $font-family-text;

	.inverted-colors {
		color: $white;
	}
}

h1 {
	@extend %title1;
}

h2 {
	@extend %title2;
}

h3 {
	@extend %title3;
}

h4 {
	@extend %title4;
}

p {
	margin-top: 0;
	margin-bottom: 1em;
}

ul:not([class]) {
	@extend %unordered_list;
}

ol:not([class]) {
	@extend %ordered_list;
}


a:not([class]) {
	color: $color-accent-primary;
	text-decoration: underline;
	font-style: italic;

	&:hover {
		color: $color-accent-primary_hover;
	}
}

em {
	font-style: italic;
}

strong {
	font-weight: $font-weight-bold;
}

sup {
	top: -0.2em !important;
}