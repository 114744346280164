
@charset "UTF-8";

@import "base.scss";

.k404 {
	
	@import "../../../scss/website/base/_buttons";
	@import "website/base/_texts";
	@import "website/layout/_default";

	@import "website/layout/_header";
	
	@import "website/components/_buttons";
	@import "website/components/_content";
}
