.row {
	margin-left: -15px !important;
	margin-right: -15px !important;
	width: auto !important;
}

@include at-most($screen-sm-max) {
	.container {
		width: 100%;
	}
}
