
// Website variables;
$grid-gutter-width: 30px;

$screen-lg-min: 1301px;
$screen-md-max: 1300px;
$container-lg: 1200px;


//===================================================================
// Colors
//===================================================================

$black: #000;
$white: #fff;

$blue49: #1e7da6;
$blue41: #196a8c;

$peacock31: #004f6a;
$peacock24: #014055;
$peacock94: #e4eff3;

$green92: #d9eeee;
$green75: #adbdbd;
$green52: #008b8b;
$green40: #017173;

$yellow78: #ffaf11; 
$yellow48: #db9623;

$red: #f00;

$gray97: #f7f7f7;
$gray95: #f2f2f2;
$gray91: #e6e6e6;
$gray81: #cbcbcb;
$gray77: #bbbfbc;
$gray58: #8c8c8c;
$gray43: #666666;
$gray15: #262626;
$gray9: #1a1a1a;

$color-text-primary: $gray9 !default;
$color-text-secondary: $white !default;
$color-text-menu-primary: $gray9 !default;

$color-accent-primary: $yellow78 !default;
$color-accent-primary_hover: $yellow48 !default;
$color-accent-secondary: $peacock31 !default;
$color-accent-secondary_hover: $peacock24 !default;

$color-background-main: $white !default;
$color-background-light: $gray95 !default;
$color-background-dark: $black !default;



//===================================================================
// Fonts
//===================================================================

$font-family-title: 'Reader', Helvetica, Arial, sans-serif;
$font-family-text: 'Reader', Helvetica, Arial, sans-serif;

$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;


$font-size-title1-lg: fs(50px) !default;
$font-size-title1-md: fs(50px) !default;
$font-size-title1-sm: fs(30px) !default;
$font-size-title1-xs: fs(30px) !default;

$font-size-title2-lg: fs(30px) !default;
$font-size-title2-md: fs(30px) !default;
$font-size-title2-sm: fs(25px) !default;
$font-size-title2-xs: fs(25px) !default; 

$font-size-title3-lg: fs(20px) !default;
$font-size-title3-md: fs(20px) !default;
$font-size-title3-sm: fs(20px) !default;
$font-size-title3-xs: fs(20px) !default;

$font-size-title4-lg: fs(16px) !default;
$font-size-title4-md: fs(16px) !default;
$font-size-title4-sm: fs(16px) !default;
$font-size-title4-xs: fs(16px) !default;

$font-size-text-lg: fs(16px) !default;
$font-size-text-md: fs(16px) !default;
$font-size-text-sm: fs(16px) !default;
$font-size-text-xs: fs(16px) !default;



$font-size-menu-lg: fs(15px);
$font-size-menu-md: fs(15px);
$font-size-menu-sm: fs(15px);
$font-size-menu-xs: fs(15px);