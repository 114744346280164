

%title {
	font-family: $font-family-title;
	font-weight: $font-weight-medium;
	line-height: 1;
	margin: 0 0 0.5em;
	text-transform: uppercase;
	//letter-spacing: letter-spacing(200);

	strong {
		color: $color-accent-primary;
		font-weight: inherit;
	}
}

%title1 {
	@extend %title;

	font-size: $font-size-title1-lg;

	@include responsive-fontsizes((
		$screen-md-max: $font-size-title1-md,
		$screen-sm-max: $font-size-title1-sm,
		$screen-xs-max: $font-size-title1-xs,
	));
}

%title2 {
	@extend %title;

	font-size: $font-size-title2-lg;
	@include responsive-fontsizes((
		$screen-md-max: $font-size-title2-md,
		$screen-sm-max: $font-size-title2-sm,
		$screen-xs-max: $font-size-title2-xs,
	));
}

%title3 {
	@extend %title;

	letter-spacing: 0.1em;
	font-size: $font-size-title3-lg;
	@include responsive-fontsizes((
		$screen-md-max: $font-size-title3-md,
		$screen-sm-max: $font-size-title3-sm,
		$screen-xs-max: $font-size-title3-xs,
	));
}


%title4 {
	@extend %title;

	text-transform: none;
	font-family: $font-family-text;
	font-weight: $font-weight-bold;
	font-size: $font-size-title4-lg;
	@include responsive-fontsizes((
		$screen-md-max: $font-size-title4-md,
		$screen-sm-max: $font-size-title4-sm,
		$screen-xs-max: $font-size-title4-xs,
	));
}

%text {
	font-family: $font-family-text;
	font-size: $font-size-text-lg;
	@include responsive-fontsizes((
		$screen-md-max: $font-size-text-md,
		$screen-sm-max: $font-size-text-sm,
		$screen-xs-max: $font-size-text-xs,
	));
}

%footer_nav_category {
	@extend %title;
	font-weight: $font-weight-bold;

	font-size: $font-size-text-lg;
	@include responsive-fontsizes((
		$screen-md-max: $font-size-text-md,
		$screen-sm-max: $font-size-text-sm,
		$screen-xs-max: $font-size-text-xs,
	));
}

%unordered_list {
	padding: 0;
	list-style-type: none;

	li {
		position: relative;
		padding-left: 30px;

		&:before {
			position: absolute;
				top: calc(1/2 * 1.4em);
				left: 0;

			transform: translate(0, -50%);

			content: ' ';
			display: block;

			width: 7px;
			height: 7px;
			background-color: $color-accent-primary;
		}

		& + li {
			margin-top: 10px;
		}
	}
}

%ordered_list {
	padding: 0;
	list-style-type: none;

	li {
		position: relative;
		padding-left: 30px;

		counter-increment: list-counter;
		
		h3 {
			margin-bottom: 0;
		}

		&:before {
			position: absolute;
				top: 1px;
				left: 0;
			
			content: counter(list-counter);

			font-family: $font-family-title;
			font-weight: $font-weight-medium;
			line-height: 1;
			margin: 0 0 0.5em;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			color: $color-accent-primary;
			font-size: $font-size-title3-lg;
			@include responsive-fontsizes((
				$screen-md-max: $font-size-title3-md,
				$screen-sm-max: $font-size-title3-sm,
				$screen-xs-max: $font-size-title3-xs,
			));
		}
	}
}