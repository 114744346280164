%base_button {
	display: inline-block;
	vertical-align: middle;

	outline: none;
	text-decoration: none;
	border: 1px solid $color-accent-primary_hover;
	background: $color-accent-primary;

	padding: 16px 20px;
	min-width: 170px;

	@extend %title;
	color: $color-text-secondary;
	font-size: fs(16px);
	font-weight: $font-weight-bold;
	text-align: center;
	transition: color 0.3s, background 0.3s; 

	&:hover {
		text-decoration: none;
		color: $color-text-secondary; 
		background: $color-accent-primary_hover;
	}

	&[disabled] {
		background-color: #ccc;
		border: 1px solid #bbbfbc;
	}
}


[class^=button] {
	@extend %base_button;
}