$grid-gutter-width: 30px;
$container-md: 1024px;
$container-lg: 1200px;

$screen-md-max: 1299px;
$screen-lg: 1300px;

$yellow75: #f5a732;
$yellow78: #ffaf01;
$yellow68: #db9623;

$white: #fff;
$black: #000;

$gray56: #808894;

$color-text-primary: $black;
$color-text-secondary: $white;
$color-text-tertiary: $gray56;

$ffReader: 'Reader', Helvetica, Arial, sans-serif;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

	$font-size-title1-lg: fs(38px);
	$font-size-title1-md: fs(38px);
	$font-size-title1-sm: fs(38px);
	$font-size-title1-xs: fs(38px);

	$font-size-title2-lg: fs(30px);
	$font-size-title2-md: fs(30px);
	$font-size-title2-sm: fs(30px);
	$font-size-title2-xs: fs(30px);

$font-size-text-lg: fs(18px);
$font-size-text-md: fs(18px);
$font-size-text-sm: fs(18px);
$font-size-text-xs: fs(18px);