i {
	font-family: 'FontAwesome';
	font-size: inherit;
}

%title {
	line-height: 1.2;
	margin: 0;
	text-transform:none;
}

h1 {
	@extend %title;
	font-size: $font-size-title1-lg;
	color: $black;
	text-align: left;
	margin-bottom: 50px;

	span {
		font-size: 45px;
		font-weight: $font-weight-bold;
	}

	@include responsive-fontsizes((
		$screen-md-max: $font-size-title1-md,
		$screen-sm-max: $font-size-title1-sm,
		$screen-xs-max: $font-size-title1-xs,
	));
}

h2 {
	@extend %title;

	font-weight: $font-weight-medium;
	font-size: $font-size-title2-lg;
	line-height: 30px;
	margin: 50px 0;
	letter-spacing: 2px;

	@include at-most($screen-sm-max) {
		margin: 30px 0;
	}

	@include responsive-fontsizes((
		$screen-md-max: $font-size-title2-md,
		$screen-sm-max: $font-size-title2-sm,
		$screen-xs-max: $font-size-title2-xs,
	));

}

p {
	span {
		a {
			font-style: normal;
			color: $color-accent-primary;
			text-decoration: underline;
		}
	}
}

