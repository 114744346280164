.k404-content {
	min-height: 400px;

	.subtitle {
		width: 350px;
	}
	p {
		width: 530px;
	}
	.cta {
		margin-top: 60px;
	}

	@include at-most($screen-sm-max) {
		min-height: inherit;
		text-align: center;

		h1 {
			text-align: center;
		}
		.subtitle {
			width: 100%;
		}
		p {
			width: 100%;
		}
	}
}
