[class^=button] {
	&[class*="-flat"] {
		display: inline-block;
		cursor: pointer;
		border: none;
		outline: none;

		background: $color-accent-primary;
		color: $color-text-secondary;
		border: solid 1px $color-accent-primary_hover;
		font-weight: $font-weight-bold;
		font-size: 16px;

		transition: background 0.3s;
		text-align: center;

		padding: 15px 50px;
		line-height: 1.4;
		margin: 0 15px;

		&:first-of-type {
			margin-left: 0px;
		}

		&:hover, &:focus {
			text-decoration: none;
			color: $color-text-secondary;
			background: $color-accent-primary_hover;
		}

		@include at-most($screen-sm-max) {
			margin: 15px auto;
			display: block;
			width: 170px;
			padding: 15px 0;

			&:first-of-type {
				margin: 15px auto;
			}
		}
	}
}
